import React, { FC, useCallback } from "@messenger/lib/teact/teact";

import { ApiGroupCall } from "@messenger/api/types";

import buildClassName from "@messenger/util/buildClassName";

import Link from "@messenger/components/ui/Link";
import { getActions } from "@messenger/global";

type OwnProps = {
  className?: string;
  groupCall?: Partial<ApiGroupCall>;
  children: React.ReactNode;
};

const GroupCallLink: FC<OwnProps> = ({ className, groupCall, children }) => {
  const { joinGroupCall } = getActions();

  const handleClick = useCallback(() => {
    if (groupCall) {
      joinGroupCall({ id: groupCall.id, accessHash: groupCall.accessHash });
    }
  }, [groupCall, joinGroupCall]);

  if (!groupCall) {
    return children;
  }

  return (
    <Link
      className={buildClassName("GroupCallLink", className)}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default GroupCallLink;
