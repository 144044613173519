import { DEBUG_ALERT_MSG } from "@messenger/config";
import { throttle } from "@messenger/util/schedulers";

window.addEventListener("error", handleErrorEvent);
window.addEventListener("unhandledrejection", handleErrorEvent);

const APP_ENV = process.env.APP_ENV;

function handleErrorEvent(e: ErrorEvent | PromiseRejectionEvent) {
  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  if (
    e instanceof ErrorEvent &&
    e.message === "ResizeObserver loop limit exceeded"
  ) {
    return;
  }

  e.preventDefault();

  handleError(e instanceof ErrorEvent ? e.error || e.message : e.reason);
}

const throttledAlert = throttle(window.alert, 1000);

export function handleError(err: Error) {
  if (APP_ENV === "development" || APP_ENV === "staging") {
    throttledAlert(
      `${DEBUG_ALERT_MSG}\n\n${err?.message || err}\n${err?.stack}`,
    );
  }
}
